<template>
  <div class="flex items-center pl-6">
    <form @submit.prevent="handleSearch()">
      <inline-button-input
        v-model="searchKeyword"
        container-class="mr-1.5"
        placeholder="Enter keywords..."
        :btn-props="{
          text: 'Search',
          type: 'Submit',
          class: 'font-semibold',
          variant: 'btn-primary-alt',
          disabled: assetsLoading || !searchKeyword.length,
          disabledClass: 'cursor-not-allowed'
        }"
        :input-props="{
          containerClass: 'w-50',
          variant: 'input-primary-red'
        }"
      />
    </form>
    <base-button
      v-if="searchInitiated"
      class="mr-1.5 font-semibold"
      variant="btn-primary"
      text="Save"
      @click="setSaveSearchModalVisibility(true)"
    />
    <base-button
      variant="btn-primary"
      class="flex items-center mr-1.5 font-semibold"
      @click="setAdvSearchModalVisibility(true)"
    >
      <template #default>
        <span class="mr-1.5 font-semibold truncate">
          Advanced Search
        </span>
        <base-svg
          class="h-3.5 w-3.5 text-white"
          src="icons/magnifyingGlass.svg"
          tag="span"
        />
      </template>
    </base-button>

    <!--
    <base-button
      variant="btn-primary"
      class="flex items-center"
      @click="setActiveDownloadsPopupVisibility(true)"
    >
      <template #default>
        <span class="mr-1.5 font-semibold truncate">
          Active Downloads
        </span>
        <base-svg
          class="h-3.5 w-3.5 text-white"
          src="icons/arrowCircleDown.svg"
          tag="span"
        />
      </template>
    </base-button>
    -->

    <!-- advance search modal -->
    <style-guide-advance-search-modal
      v-model="showAdvanceSearchModal"
      @hide="setAdvSearchModalVisibility(false)"
    />
    <!-- save search modal -->
    <save-search-modal
      v-model="showSaveSearchModal"
      @hide="setSaveSearchModalVisibility(false)"
    />
    <!-- active downloads popup -->
    <style-guide-active-downloads-popup
      v-model="showActiveDownloadsPopup"
      :loader="isActiveDownloadsLoading"
    />
  </div>
</template>

<script>
import { defineAsyncComponent, ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import BaseButton from '@/components/generic-components/BaseButton.vue';
import InlineButtonInput from '@/components/InlineButtonInput.vue';
import useStyleGuideAssets from '@/hooks/styleGuideAssets.js';
import StyleGuideAdvanceSearchModal from '@/components/StyleGuideAdvanceSearchModal.vue';
import SaveSearchModal from '@/components/style-guide-save-search/SaveSearchModal.vue';
import StyleGuideActiveDownloadsPopup from '@/components/StyleGuideActiveDownloadsPopup.vue';

export default {
    name: 'StyleGuideFeatureControllers',

    components: {
        BaseButton,
        InlineButtonInput,
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
        StyleGuideAdvanceSearchModal,
        SaveSearchModal,
        StyleGuideActiveDownloadsPopup
    },

    setup () {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();

        const { assetsLoading, searchAssets, clearSearchParams, saveSearchParams, resetAssetsAndSearch } = useStyleGuideAssets();
        const searchInitiated = computed(() => store.getters['assetsSearch/getSearchInitiated']);

        // search by keyword logic
        const searchKeyword = ref('');
        watch(
            () => searchKeyword.value,
            () => {
                saveSearchParams({ keyword: searchKeyword.value }, true);
            }
        );

        const handleSearch = async (resetParams = true) => {
            if (resetParams) {
                clearSearchParams();
                resetAssetsAndSearch();
            }
            store.dispatch('assets/setSearchPage', 1);

            await saveSearchParams({ keyword: searchKeyword.value });
            await searchAssets();
            if (route.name !== 'StyleGuide') router.push({ name: 'StyleGuide' });
        };

        // advance search logic
        const showAdvanceSearchModal = ref(false);
        const setAdvSearchModalVisibility = (visibility) => {
            showAdvanceSearchModal.value = visibility;
        };

        // save search logic
        const showSaveSearchModal = ref(false);
        const setSaveSearchModalVisibility = (visibility) => {
            showSaveSearchModal.value = visibility;
        };

        const isActiveDownloadsLoading = ref(false);
        const fetchActiveDowloadList = async () => {
            try {
                isActiveDownloadsLoading.value = true;
                await store.dispatch('activeDownloads/fetchActiveDowloadList');
            } catch (err) {
                console.error(err);
            } finally {
                isActiveDownloadsLoading.value = false;
            }
        };

        // active downloads logic
        const showActiveDownloadsPopup = ref(false);
        const setActiveDownloadsPopupVisibility = (visibility) => {
            fetchActiveDowloadList();
            showActiveDownloadsPopup.value = visibility;
        };

        return {
            searchKeyword,
            assetsLoading,
            handleSearch,
            showAdvanceSearchModal,
            setAdvSearchModalVisibility,
            showSaveSearchModal,
            setSaveSearchModalVisibility,
            showActiveDownloadsPopup,
            setActiveDownloadsPopupVisibility,
            searchInitiated,
            isActiveDownloadsLoading
        };
    }
};
</script>
